exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-book-index-tsx": () => import("./../../../src/pages/book/index.tsx" /* webpackChunkName: "component---src-pages-book-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index.old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-index-rest-tsx": () => import("./../../../src/pages/index.rest.tsx" /* webpackChunkName: "component---src-pages-index-rest-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interview-index-tsx": () => import("./../../../src/pages/interview/index.tsx" /* webpackChunkName: "component---src-pages-interview-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-location-index-tsx": () => import("./../../../src/pages/location/index.tsx" /* webpackChunkName: "component---src-pages-location-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-story-index-tsx": () => import("./../../../src/pages/story/index.tsx" /* webpackChunkName: "component---src-pages-story-index-tsx" */),
  "component---src-pages-universty-info-index-tsx": () => import("./../../../src/pages/universtyInfo/index.tsx" /* webpackChunkName: "component---src-pages-universty-info-index-tsx" */)
}

